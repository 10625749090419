.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #063d9e;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-calendar {
  width: 100% !important;
  button {
    height: 100px;
  }
  &__tile--active {
    background-color: #063d9e;
  }
  &__navigation {
    min-height: 100px;
    margin-bottom: 0;
    &__label__labelText {
      font-size: 20px;
    }
  }
}
